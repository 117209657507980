import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown  from 'react-bootstrap/Dropdown';
import DropdownButton  from 'react-bootstrap/DropdownButton';
import LoginViewStart from '../../App_full_start/LoginViewStart';
import 'bootstrap/dist/css/bootstrap.min.css';


export default class  Navbartop extends React.Component {
    /*constructor(props){
      super(props);
    }*/
    LoginFunction(thiss) {
      document.getElementById("author_login_div_id").style.display = "block";
      thiss.props.AddUser_menu("Registration");
    }
    Event_entry_user(thiss) {
      document.getElementById("author_login_div_id").style.display = "block";
      thiss.props.AddUser_menu("EntryUser");
    }
   
    componentDidMount(){
  
    
    }
  
    render(){
    return (
      <Navbar collapseOnSelect="true" sticky="top" expand="lg" bg="light" variant="light">
        <LoginViewStart />
      <Navbar.Brand href="#home">Мням-Мням</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#features">Сформировать дизайн продукта</Nav.Link>
          <Nav.Link href="#pricing">Созать пользовательские элементы</Nav.Link>
          
        </Nav>
        
        <Nav>
        <DropdownButton id="dropdown-variants-Secondary" key="Secondary" variant="Secondary" title={this.props.user.user}>
          <Dropdown.Divider />
          <Dropdown.Item id="dropdown-variants-Secondary" key="Secondary" variant="Secondary" href="#" onClick={() => this.props.AddUser_datas("Анонимный пользователь",  "")}>Выйти</Dropdown.Item>
        </DropdownButton>
          <Nav.Link href="#" onClick={() =>this.Event_entry_user(this)}> Вход </Nav.Link>
          <Nav.Link href="#" onClick={() =>this.LoginFunction(this)}> Регистрация </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    
    );
    }
  }
  