import * as types from '../resucers/types';


function AddUser_datas(user, token){

    return dispatch => {

        dispatch({
            type: types.user.USER_INFO,
            user:{
                type: types.user.USER_INFO,
                user: user,
                token: token
               
            },
        })

      
    }

}

/* function AddUser_datas(){

    return dispatch => {
        fetch('', {
            method:'POST'

        })
        .then(
            function(response) {
                if(response.status !== 200){

                }
                response.json().then(function(data) {
                    dispatch({
                        type: types.user.USER_INFO,
                        user:{
                            type: types.user.USER_INFO,
                            user: 'anonim',
                            token: '11111111'
                        },
                    })
                })
            }
        )
    }

} */




export default AddUser_datas;