import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/LoginView.css';
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";
import $ from 'jquery'; 



export default class  FormLogin extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        login_valid: '',
        ferst_name_valid: '',
        last_name_valid: '',
        email_valid: '',
        dateBirth: '',
        password_1_valid: '',
        password_2_valid: '',
        startDate: new Date(),
        startDate_valid: ''
      }
    }

    componentDidMount() {
    
     
     // var props_data = this.props;
    
    }

  

    

        
    close_login_view() {
      document.getElementById("author_login_div_id").style.display = "none";
     
    }

    

    handleChange = (name, event) => {
          
     
      switch (name) {
        case 'data_birth':
            this.setState({
              startDate: event
            });
            var lenght_startDate = String(event).length;
            this.setState({
              startDate_valid: lenght_startDate > 2 ? true : false
            });
          break;
        case 'login': 
            var lenght_login = event.target.value.length;
          this.setState({
            login_valid: lenght_login > 2 ? true : false
          });
          break;
        case 'first_name': 
          var lenght_ferst_name = event.target.value.length;
          this.setState({
            ferst_name_valid: lenght_ferst_name > 2 ? true : false
          });
          break;
        case 'last_name': 
        var lenght_last_name = event.target.value.length;
          this.setState({
            last_name_valid: lenght_last_name > 2 ? true : false
          });
          break;
        case 'email': 
        var lenght_email = event.target.value.length;
          this.setState({
            email_valid: lenght_email > 2 ? true : false
          });
          break;
        case 'password1': 
        var lenght_password1 = event.target.value.length;
          this.setState({
            password_1_valid: lenght_password1 > 2 ? true : false
          });
          break;
        case 'password2': 
        var lenght_password2 = event.target.value.length;
          this.setState({
            password_2_valid: lenght_password2 > 2 ? true : false
          });
          break;
        default:
          break;
      }
  


      
        !this.state.login_valid ? document.getElementsByName("login_LoginView")[0].style.border="1px solid red": document.getElementsByName("login_LoginView")[0].style.border="0px";
        !this.state.ferst_name_valid ? document.getElementsByName("first_name_LoginView")[0].style.border="1px solid red": document.getElementsByName("first_name_LoginView")[0].style.border="0px";
        !this.state.last_name_valid ? document.getElementsByName("last_name_LoginView")[0].style.border="1px solid red": document.getElementsByName("last_name_LoginView")[0].style.border="0px";
        !this.state.email_valid ? document.getElementsByName("eMail_LoginView")[0].style.border="1px solid red": document.getElementsByName("eMail_LoginView")[0].style.border="0px";
        !this.state.startDate_valid ? document.getElementsByName("Date_name_LoginView")[0].style.border="1px solid red": document.getElementsByName("Date_name_LoginView")[0].style.border="0px";
        !this.state.password_1_valid ? document.getElementsByName("password1_LoginView")[0].style.border="1px solid red": document.getElementsByName("password1_LoginView")[0].style.border="0px";
        !this.state.password_2_valid ? document.getElementsByName("password2_LoginView")[0].style.border="1px solid red": document.getElementsByName("password2_LoginView")[0].style.border="0px";

       


    }




  
   

   

    handleSubmit = (event) => {
     
      event.preventDefault();
      var login_name = event.target.elements.login_LoginView.value;
     var first_name = event.target.elements.first_name_LoginView.value;
     var last_name = event.target.elements.last_name_LoginView.value;
     var eMail_value = event.target.elements.eMail_LoginView.value;
     var Dates_value = event.target.elements.Date_name_LoginView.value;
     var Password_1_value = event.target.elements.password1_LoginView.value;
     var Password_2_value = event.target.elements.formAuthorizConfirmPassword.value;
  
     

     if(this.state.login_valid && this.state.ferst_name_valid && this.state.last_name_valid && this.state.email_valid && this.state.startDate_valid && this.state.password_1_valid && this.state.password_1_valid && this.state.password_2_valid)
     {

      if(Password_1_value === Password_2_value)
      {
        document.getElementsByName("password1_LoginView")[0].style.border="0px";
        document.getElementsByName("password2_LoginView")[0].style.border="0px";

          fetch('https://administration.quickcake.ru/api/CreateUser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              login: login_name, 
              password: Password_1_value,
              first_name: first_name,
              last_name: last_name,
              email: eMail_value,
              Birthday: Dates_value

            })
          }).then(
            function(response) {
              if (response.status !== 200) {                
                document.getElementById("set_result_login_query").textContent = response.text();
              }
        
              // Examine the text in the response
              response.json().then(function(data) {
                  $('#set_result_login_query').html(data);
              });
            }
          )
          .catch(function(err) {
            document.getElementById("set_result_login_query").textContent = err;
          });
        }
        else{
          document.getElementsByName("password1_LoginView")[0].style.border="1px solid red";
          document.getElementsByName("password2_LoginView")[0].style.border="1px solid red";
        }

    }
 
  }
  
    render(){
    
      console.log(this.props.menu);

      const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
      };
      const years = range(1930, getYear(new Date()));
      const months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ];
    return (
    
      <div >
      <Form onSubmit={this.handleSubmit}>

          <Form.Group as={Row} >
            <Form.Label style={{marginLeft: 160, marginTop: 60, marginBottom: 25, fontFamily: 'Arial, sans-serif', fontSize: 24, fontWeight: "bold"}} >
              Форма регистрации пользователя
            </Form.Label>
           
          </Form.Group>
        
          <Form.Group as={Row} controlId="formAuthorizFerstName">
            <Form.Label style={{marginLeft: 110, marginTop: 5}} >
              Логин
            </Form.Label>
            <Col sm={7} style={{marginLeft: 40}}>
              <Form.Control type="text" placeholder="Логин"  name='login_LoginView' onChange={this.handleChange.bind(this, "login")}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formAuthorizFerstName">
            <Form.Label style={{marginLeft: 125, marginTop: 5}} >
              Имя
            </Form.Label>
            <Col sm={7} style={{marginLeft: 40}}>
              <Form.Control type="text" placeholder="Имя" name='first_name_LoginView' onChange={this.handleChange.bind(this, "first_name")}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formAuthorizLastName">
            <Form.Label style={{marginLeft: 90, marginTop: 5}}>
              Фамилия
            </Form.Label>
            <Col sm={7} style={{marginLeft: 40}}> 
              <Form.Control type="text" placeholder="Фамилия" name='last_name_LoginView' onChange={this.handleChange.bind(this, "last_name")}/>
            </Col>
          </Form.Group>
         
          <Form.Group as={Row} controlId="formAuthorizEMail">
            <Form.Label style={{marginLeft: 120, marginTop: 5}}>
              Email
            </Form.Label>
            <Col sm={7} style={{marginLeft: 40}}>
              <Form.Control type="email" placeholder="Email" name='eMail_LoginView' onChange={this.handleChange.bind(this, "email")}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formAuthorizData">
            <Form.Label style={{marginLeft: 45, marginTop: 5}}>
              Дата рождения
            </Form.Label>
            <Col sm={5} style={{marginLeft: 15}}>
            <DatePicker
              locale={ru}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    {"<"}
                  </button>
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    {">"}
                  </button>
                </div>
              )}
              selected={this.state.startDate} 
              onChange={this.handleChange.bind(this, "data_birth")}  name='Date_name_LoginView'/>
            </Col>
          </Form.Group>
          

          <Form.Group as={Row} controlId="formAuthorizPassword">
            <Form.Label style={{marginLeft: 102, marginTop: 5}}>
              Пароль
            </Form.Label>
            <Col sm={7} style={{marginLeft: 40}}>
              <Form.Control type="password" placeholder="Пароль" name='password1_LoginView' onChange={this.handleChange.bind(this, "password1")}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formAuthorizConfirmPassword">
            <Form.Label style={{marginLeft: 40, marginTop: 5}}>
             Подтверждение
            </Form.Label>
            <Col sm={7} style={{marginLeft: 40}}>
              <Form.Control type="password" placeholder="Подтверждение пароля" name='password2_LoginView' onChange={this.handleChange.bind(this, "password2")}/>
            </Col>
          </Form.Group>
    
         
          <Form.Group as={Row}>
            <Col sm={{ span: 10, offset: 2}} style={{marginTop: 20}}>
              <Button type="submit">Регистрация</Button>
            </Col>
          </Form.Group>
      </Form>
      <div>
        <span id="set_result_login_query"></span>
      </div>
      <div>
        <a href="#"  style={{marginLeft: 100}}  onClick={() => this.props.AddUser_menu("ChangePassword")}>Смените пароль!</a> 
      </div>
   </div>
    );
    }
  }
  