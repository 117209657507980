import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery'; 
import '../Authorization/css/ChangePassword.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';


export default class  ChangePassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      email_valid: 'false',
    
    }
  }

    handleChange = (name, event) => {
          
     
      switch (name) {
        case 'email': 
        var lenght_email = event.target.value.length;
          this.setState({
            email_valid: lenght_email > 2 ? true : false
          });
          break;
        default:
          break;
      }
       
     
        !this.state.email_valid ? document.getElementsByName("eMail_ChangePassword")[0].style.border="1px solid red": document.getElementsByName("eMail_ChangePassword")[0].style.border="0px";
       
    }



    handleSubmit = (event) => {
     
      event.preventDefault();
    
     var eMail_value = event.target.elements.eMail_ChangePassword.value;
     
  
     

     if(this.state.email_valid)
     {

     
        

          fetch('https://administration.quickcake.ru/api/ChangePassword', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
           
              email: eMail_value
            

            })
          }).then(
            function(response) {
              if (response.status !== 200) {                
                document.getElementById("set_result_changePassword_query").textContent = response.text();
                console.log(response.text());
              }
        
              // Examine the text in the response
              response.json().then(function(data) {
              //  document.getElementById("set_result_changePassword_query").textContent = data;
                $('#set_result_changePassword_query').html(data);
                console.log(data);
              });
            }
          )
          .catch(function(err) {
            document.getElementById("set_result_changePassword_query").textContent = err;
            console.log(err);
          });
       

    }
    else{

    }
 
  }


    

    componentDidMount(){
  
     
    }
  
    render(){
    return (
     <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group as={Row} >
            <Form.Label style={{marginLeft: 175, marginTop: 60, marginBottom: 25, fontFamily: 'Arial, sans-serif', fontSize: 22, fontWeight: "bold"}} >
              Форма смены пароля
            </Form.Label>
          
          </Form.Group>
          <Form.Group as={Row} controlId="formChangePasswordEMail">
            <Form.Label style={{marginLeft: 80, marginTop: 5}}>
              Email
            </Form.Label>
            <Col sm={7} style={{marginLeft: 20}}>
              <Form.Control type="email" placeholder="Email" name='eMail_ChangePassword'  onChange={this.handleChange.bind(this, "email")}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
              <Col sm={{ span: 8, offset: 2}} style={{marginTop: 20}}>
                <Button type="submit" >Сменить пароль</Button>
              </Col>
            </Form.Group>
        </Form>
        <div id="set_result_changePassword_query"></div>
     </div>
    
    );
    }
  }
  