import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/LoginView.css';
import "react-datepicker/dist/react-datepicker.css";
import  FormLoginStart  from "../../App_full_start/FormLoginStart";
import ChangePasswordStart from "../../App_full_start/ChangePasswordStart";
import EntryUserStart from "../../App_full_start/EntryUserStart";


function RenderForms(props) {
  var value_menu = props.menu;
      if (value_menu.menu === "Registration") {
        return (<FormLoginStart />);
      }
      else if(value_menu.menu === "ChangePassword"){
        return (<ChangePasswordStart />);
      }
      else if(value_menu.menu === "EntryUser"){
        return (<EntryUserStart />);
      }
      else{
        return (<div>Компонент отсутствует</div>);
      }
}


export default class  LoginView extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        
      }
    }
 
    componentDidMount() {
    //  this.props.AddUser_menu("Проверка");
        
    
    }

            
    close_login_view(thiss) {
      document.getElementById("author_login_div_id").style.display = "none";
     // var windows_data = windows;
     thiss.props.AddUser_menu("Registration");
    }
  
  
    render(){
    
    
    return (
     <div id='author_login_div_id' className="author_login_div_css">
        <button type="button" className="close"  onClick={() =>this.close_login_view(this)} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

      <div id="author_login_divForm_id">
        <RenderForms  menu={this.props.menu}/>
      </div>
     </div>
    );
    }
  }
  