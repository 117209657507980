import AddUser_datas from '../actions/action_user';
import AddUser_menu from '../actions/action_user_menu';


const mapDispatchToProps = (dispatch) => {
    return{
        AddUser_datas: (user, token) => {
          dispatch(AddUser_datas(user, token))  
        },
        AddUser_menu: (data_st) => {
          dispatch(AddUser_menu(data_st))  
        },
    }
}

export default mapDispatchToProps;