import React from 'react';
import './App.css';
import NavBarStart from './App_full_start/NavBarStart';


export default class  App extends React.Component {
  /*constructor(props){
    super(props);
  }*/
  componentDidMount(){
  
   // var props_data = this.props;
    //console.log(props_data);
  }

  render(){
  return (
    <div className="App">
      <NavBarStart />
      <div style={{marginRight: 100, marginTop: 350, fontFamily: 'Arial, sans-serif', fontSize: 35, fontWeight: "bold", color: 'red'}}><span>Извините, сайт находится в разработке!</span></div>
    </div>
  );
  }
}


