import * as types from '../resucers/types';


export const initialState = {
    interface:[{
        type: types.app_general.APP_GENERAL,
        search: ''
    }],


}

function reducer_appGeneral(state, action){
    if(typeof state === 'undefined'){
        return initialState
    }
    switch(action.type){
        case types.app_general.APP_GENERAL:
            let State_new = Object.assign({}, state);
            let state_interface = State_new.interface;
            let state_interface_result = state_interface.concat(action.interface);
            State_new.interface = state_interface_result;
            return State_new;
        default:
            return state;
    }

}
export default reducer_appGeneral;