import * as types from '../resucers/types';

function AddUser_menu(data_st){

    return dispatch => {

        dispatch({
            type: types.user.USER_MENU,
            menu:{
                type: types.user.USER_MENU,
                menu: data_st,
               
            },
        })

      
    }

}
export default AddUser_menu;