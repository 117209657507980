import * as types from '../resucers/types';


export const initialState = {
    user:[{
        type: types.user.USER_INFO,
        user: 'Анонимный пользователь',
        token: ''
    }],
    menu:[{
        type: types.user.USER_MENU,
        menu: 'Registration',
    }],


}

function reducer_user(state, action){
    if(typeof state === 'undefined'){
        return initialState
    }
    switch(action.type){
        case types.user.USER_INFO:
            let State_new = Object.assign({}, state);
            let state_user = State_new.user;
            let state_user_result = state_user.concat(action.user);
            State_new.user = state_user_result;
            return State_new;
        case types.user.USER_MENU:
            let State_menu_new = Object.assign({}, state);
            let state_user_menu = State_menu_new.menu;
            let state_user_menu_result = state_user_menu.concat(action.menu);
            State_menu_new.menu = state_user_menu_result;
            return State_menu_new;
        default:
            return state;
    }

}
export default reducer_user;