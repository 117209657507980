import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery'; 
import '../Authorization/css/ChangePassword.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';


export default class  EntryUser extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      login_valid: 'false',
      password_valid: 'false',
      
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

    
  

    handleChange = (name, event) => {
          
     
      switch (name) {
        case 'login': 
        var lenght_login = event.target.value.length;
          this.setState({
            login_valid: lenght_login > 2 ? true : false
          });
          break;
        case 'password': 
          var lenght_password = event.target.value.length;
            this.setState({
              password_valid: lenght_password > 2 ? true : false
            });
            break;
        default:
          break;
      }
       
     
        !this.state.login_valid ? document.getElementsByName("login_EntryUser")[0].style.border="1px solid red": document.getElementsByName("login_EntryUser")[0].style.border="0px";
        !this.state.password_valid ? document.getElementsByName("password_EntryUser")[0].style.border="1px solid red": document.getElementsByName("password_EntryUser")[0].style.border="0px";
    }



    handleSubmit = (event) => {
     event.preventDefault();
      var thiss = this;
     var login_value = event.target.elements.login_EntryUser.value;
     var password_value = event.target.elements.password_EntryUser.value;
     
  
     

     if(this.state.login_valid && this.state.password_valid)
     {

     
        

          fetch('https://administration.quickcake.ru/api/Account', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
           
              username: login_value,
              password: password_value
            

            })
          }).then(
            function(response) {
              if (response.status !== 200) {                
                document.getElementById("set_result_EntryUser_query").textContent = response.text();
               // console.log(response.text());
              }
        
              // Examine the text in the response
              response.json().then(function(data) {
              //  document.getElementById("set_result_changePassword_query").textContent = data;
              thiss.props.AddUser_datas(data.username,  data.access_token);
                $('#set_result_EntryUser_query').html(thiss.props.user.user);
                console.log(thiss.props.user );
              });
            }
          )
          .catch(function(err) {
            document.getElementById("set_result_EntryUser_query").textContent = err;
            console.log(err);
          });
       

    }
    else{

    }
 
  }


    

    componentDidMount(){
  
     
    }
  
    render(){
    return (
     <div>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Group as={Row} >
            <Form.Label style={{marginLeft: 220, marginTop: 60, marginBottom: 25, fontFamily: 'Arial, sans-serif', fontSize: 22, fontWeight: "bold"}} >
              Форма входа
            </Form.Label>
            </Form.Group>
          
            <Form.Group as={Row} controlId="formAuthorizEntryUserLogin">
                <Form.Label style={{marginLeft: 50, marginTop: 5}} >
                    Логин
                </Form.Label>
                <Col sm={7} style={{marginLeft: 40}}>
                <Form.Control type="text" placeholder="Логин"  name='login_EntryUser' onChange={this.handleChange.bind(this, "login")}/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formAuthorizEntryUserPassword">
                <Form.Label style={{marginLeft: 40, marginTop: 5}}>
                Пароль
                </Form.Label>
                <Col sm={7} style={{marginLeft: 40}}>
                <Form.Control type="password" placeholder="Введите пароль" name='password_EntryUser' onChange={this.handleChange.bind(this, "password")}/>
                </Col>
            </Form.Group>

          <Form.Group as={Row}>
              <Col sm={{ span: 8, offset: 2}} style={{marginTop: 20}}>
                <Button type="submit">Войти</Button>
              </Col>
            </Form.Group>
        </Form>
        <div id="set_result_EntryUser_query"></div>
     </div>
    
    );
    }
  }
  