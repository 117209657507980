import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import reducer_user from '../resucers/reducer_user';
import reducer_appGeneral from '../resucers/reducer_appGeneral';

const storeReducer = combineReducers({

    UserStore: reducer_user,
    AppGeneralStore: reducer_appGeneral


});

const userReducer = createStore(storeReducer, composeWithDevTools(applyMiddleware(thunk)));
export default userReducer;